import { Button } from "@mui/material";
import React from "react";

export const DialogBtn = ({ buttonStyle, onClick, title, type, disabled }) => {
  return (
    <Button
      type={type}
      sx={{
        paddingX: 4,
        color: "white",

        fontWeight: 700,
        textTransform: "capitalize",
        boxShadow: "0px 4px 4px 0px #00000040",
        ...buttonStyle,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </Button>
  );
};
