import React, { useEffect, useState } from "react";
import "./CityDetailsForm.css";
import { State, City } from "country-state-city";
import {
  Box,
  InputLabel,
  TextField,
  FormControlLabel,
  Typography,
  Checkbox,
  Button,
  Autocomplete,
} from "@mui/material";
import styled from "@emotion/styled";
import CityMultiSelectDropdown from "./CityMultiSelectDropdown";
import InterCityMultiSelect from "./InterCityMultiSelect";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import useToast from "../../../../hooks/useToast";

const StyledInputLabel = styled(InputLabel)(() => ({
  color: "#333",
  fontWeight: 700,
  fontSize: "16px",
  marginTop: "10px",
  marginBottom: "10px",
}));
const ErrorText = styled(Typography)(() => ({
  color: "#d32f2f",
  fontSize: "12px",
  margin: "0 14px",
}));
const Header = styled(Box)(() => ({
  width: "100%",
  background: "#EAEAEA",
  height: "48px",
  display: "flex",
  fontWeight: "700",
  fontSize: "18px",
  justifyContent: "flex-start",
  alignItems: "center",
  paddingLeft: "1%",
  gap: "40%",
}));

const StyledButton = styled(Button)(() => ({
  fontSize: "14px",
  fontWeight: "700",
  textDecoration: "underline",
  color: "#FF3F3F",
  textTransform: "none",
}));

export default function CityDetailsForm(props) {
  const { handleOpenToast, ToastMessage } = useToast();

  const { selectedCity, cities } = props;

  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [stateNames, setStateNames] = useState([]);
  const [citiesLatitudeLongitude, setCitiesLatitudeLongitude] = useState([]);
  const [interCityAreas, setInterCityAreas] = useState(
    cities.map((i) => i.city)
  );

  const [formValues, setFormValues] = useState({
    state: null,
    city: null,
    serviceAreas: [],
    isAllServiceAreas: false,
    baseAreas: [],
    isAllBaseAreas: false,
    isIntraCity: true,
    interCityAreas: [],
  });

  const [formErrors, setFormErrors] = useState({
    state: false,
    city: false,
    isIntraCity: false,
    serviceAreas: false,
    baseAreas: false,
  });

  useEffect(() => {
    const indiaStates = State.getStatesOfCountry("IN");
    const stateNamesRes = indiaStates.map((state) => state.name);
    setStatesList(indiaStates);
    setStateNames(stateNamesRes);
  }, []);

  useEffect(() => {
    const stateCodeObj = statesList.find(
      (item) => item.name === formValues.state
    );
    const listOfCities = City.getCitiesOfState("IN", stateCodeObj?.isoCode);
    setCitiesList(listOfCities.map((i) => i.name));
    setCitiesLatitudeLongitude(
      listOfCities.map((i) => {
        return {
          city: i.name,
          latitude: i.latitude,
          longitude: i.longitude,
        };
      })
    );
  }, [formValues?.state]);

  useEffect(() => {
    setInterCityAreas(cities.map((i) => i.city));
  }, [cities]);

  useEffect(() => {
    if (selectedCity) {
      setFormValues({
        state: selectedCity.state,
        city: selectedCity.city,
        serviceAreas: selectedCity.serviceAreas.map(
          ({ _id, loc, ...other }) => other
        ),
        isAllServiceAreas: selectedCity.allServiceAreasSelected,
        baseAreas: selectedCity.baseAreas.map(
          ({ _id, loc, ...other }) => other
        ),
        isAllBaseAreas: selectedCity.allBaseAreasSelected,
        isIntraCity: selectedCity.isIntraCity,
        interCityAreas: selectedCity.interCityRoutes,
      });
    }
  }, [selectedCity]);

  const validateForm = () => {
    const errors = {};

    if (!formValues.state) {
      errors.state = true;
    }

    if (!formValues.city) {
      errors.city = true;
    }

    if (formValues.isIntraCity) {
      if (!formValues.isAllServiceAreas && !formValues.serviceAreas.length) {
        errors.serviceAreas = true;
      }

      if (!formValues.isAllBaseAreas && !formValues.baseAreas.length) {
        errors.baseAreas = true;
      }
    }

    setFormErrors(errors);

    return Object.values(errors).includes(true);
  };

  const handleSubmit = async () => {
    const hasErrors = validateForm();

    if (hasErrors) return;

    if (formValues.interCityAreas.includes(formValues.city)) {
      handleOpenToast("City and intercity route can't be same.", "error");
      return;
    }

    const citySelected = citiesLatitudeLongitude.find(
      (city) => city.city === formValues.city
    );
    const body = {
      state: formValues.state,
      city: formValues.city,
      latitude: citySelected.latitude,
      longitude: citySelected.longitude,
      isIntraCity: formValues.isIntraCity,
      interCityRoutes: formValues.interCityAreas,
    };
    if (formValues.isIntraCity) {
      body.allBaseAreasSelected = formValues.isAllBaseAreas;
      body.allServiceAreasSelected = formValues.isAllServiceAreas;
      body.baseAreas = formValues.baseAreas;
      body.serviceAreas = formValues.serviceAreas;
    }

    if (selectedCity) {
      await props.editCityData(body);
    } else {
      await props.createCity(body);
    }
  };

  const onRemoveCityButtonClick = async () => {
    try {
      const body = {
        cityName: selectedCity.city,
      };
      await props.openDeleteCityConfirmationModal(body);
    } catch (error) {
      handleOpenToast("Error while removing city.", "error");
    }
  };

  return (
    <Box style={{ height: "100%", overflow: "hidden" }}>
      <ToastMessage />
      <Header>
        <ArrowBackIosNewIcon
          onClick={props.handleBackIconClick}
          sx={{ cursor: "pointer" }}
        />
        <Box>{selectedCity ? "Edit City" : "Add City"}</Box>
      </Header>
      <Box
        sx={{
          padding: "20px",
          width: "100%",
          height: "calc(100% - 77px)",
          overflow: "auto",
          paddingBottom: "80px",
        }}
      >
        <Box>
          <StyledInputLabel>
            Add Your State <span style={{ color: "#d32f2f" }}>*</span>
          </StyledInputLabel>

          <Autocomplete
            disableClearable
            value={formValues.state}
            disabled={selectedCity ? true : false}
            onChange={(e, value) => {
              setFormValues({ ...formValues, state: value, city: "" });
            }}
            options={stateNames}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select your State"
                inputProps={{
                  ...params.inputProps,
                  style: {
                    padding: "8px 12px",
                  },
                }}
              />
            )}
            renderTags={() => <div />}
            sx={{
              ...(formErrors.state
                ? {
                    fieldset: {
                      borderColor: "#d32f2f !important",
                    },
                  }
                : {}),
            }}
          />
          {formErrors.state && <ErrorText>State name is required.</ErrorText>}
        </Box>

        <Box>
          <StyledInputLabel>
            Add Your City <span style={{ color: "#d32f2f" }}>*</span>
          </StyledInputLabel>

          <Autocomplete
            disableClearable
            value={formValues.city}
            disabled={selectedCity ? true : false}
            onChange={(e, value) => {
              setFormValues({ ...formValues, city: value });
            }}
            options={citiesList}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select your City"
                inputProps={{
                  ...params.inputProps,
                  style: {
                    padding: "8px 12px",
                  },
                }}
              />
            )}
            renderTags={() => <div />}
            sx={{
              ...(formErrors.state
                ? {
                    fieldset: {
                      borderColor: "#d32f2f !important",
                    },
                  }
                : {}),
            }}
          />
          {formErrors.city && <ErrorText>City name is required.</ErrorText>}
        </Box>

        <Box>
          <FormControlLabel
            label={
              <Typography fontSize="14px" fontWeight="400" color="#6D6D6D">
                We provide Intracity transport
              </Typography>
            }
            control={
              <Checkbox
                color="success"
                checked={formValues.isIntraCity}
                onChange={() => {
                  setFormValues({
                    ...formValues,
                    isIntraCity: !formValues.isIntraCity,
                  });
                }}
              />
            }
          />
        </Box>

        {formValues.isIntraCity && formValues.city && (
          <>
            <Box>
              <StyledInputLabel>
                Add your service areas{" "}
                <span style={{ color: "#d32f2f" }}>*</span>
              </StyledInputLabel>

              <CityMultiSelectDropdown
                selectedItems={formValues.serviceAreas}
                setItems={(items) => {
                  setFormValues({
                    ...formValues,
                    serviceAreas: items,
                    isAllServiceAreas: false,
                  });
                }}
                placeholder="Enter your service areas"
                error={formErrors.serviceAreas}
                helperText="Service locations are required."
                city={formValues.city}
              />

              <FormControlLabel
                label={
                  <Typography fontSize="14px" fontWeight="400" color="#6D6D6D">
                    select all service areas
                  </Typography>
                }
                control={
                  <Checkbox
                    color="success"
                    checked={formValues.isAllServiceAreas}
                    onChange={() => {
                      setFormValues({
                        ...formValues,
                        serviceAreas: [],
                        isAllServiceAreas: !formValues.isAllServiceAreas,
                      });
                    }}
                  />
                }
              />
            </Box>

            <Box>
              <StyledInputLabel>
                Add your base areas <span style={{ color: "#d32f2f" }}>*</span>
              </StyledInputLabel>

              <CityMultiSelectDropdown
                selectedItems={formValues.baseAreas}
                setItems={(items) => {
                  setFormValues({
                    ...formValues,
                    baseAreas: items,
                    isAllBaseAreas: false,
                  });
                }}
                placeholder="Enter your base areas"
                error={formErrors.baseAreas}
                helperText="Base locations are required."
                city={formValues.city}
              />

              <FormControlLabel
                label={
                  <Typography fontSize="14px" fontWeight="400" color="#6D6D6D">
                    select all base areas
                  </Typography>
                }
                control={
                  <Checkbox
                    color="success"
                    checked={formValues.isAllBaseAreas}
                    onChange={() => {
                      setFormValues({
                        ...formValues,
                        baseAreas: [],
                        isAllBaseAreas: !formValues.isAllBaseAreas,
                      });
                    }}
                  />
                }
              />
            </Box>
          </>
        )}

        {formValues.city && interCityAreas.length > 1 && (
          <Box>
            <StyledInputLabel>
              Select cities for intercity transport
            </StyledInputLabel>
            <InterCityMultiSelect
              options={interCityAreas}
              selectedItems={formValues.interCityAreas}
              setItems={(items) => {
                setFormValues({
                  ...formValues,
                  interCityAreas: items,
                });
              }}
              placeholder="Select cities for intercity transport"
              city={formValues.city}
            />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          position: "sticky",
          padding: "0px 40px",
          bottom: "20px",
          backgroundColor: "#fff",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {selectedCity ? (
            <StyledButton onClick={onRemoveCityButtonClick}>
              Remove city
            </StyledButton>
          ) : (
            <Box></Box>
          )}
          <Button variant="contained" onClick={handleSubmit}>
            {selectedCity ? "Save" : "Add"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
