import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { DialogBtn } from "../Common/DialogBtn";
import { CustomTextInput } from "../Common/CustomTextInput";

import {
  CONFIRM_BOOKING_DIALOG_STEPS,
  SAVING_STATUS,
} from "../../constants/constant";

function WhatsAppPickUpDetails({
  setStep,
  setContactPersonDetails,
  contactPersonDetails,
  enquiryDetails,
}) {
  const [isContactDetailsUploaded, setIsContactDetailsUploaded] = useState("");
  const [pickupContactPersonName, setPickupContactPersonName] = useState(
    enquiryDetails.customer.customerName
  );
  const [pickupContactPersonNumber, setPickupContactPersonNumber] = useState(
    enquiryDetails.customer.customerPhone
  );
  const [dropOffContactPersonName, setDropOffContactPersonName] = useState(
    enquiryDetails.customer.customerName
  );
  const [dropOffContactPersonNumber, setDropOffContactPersonNumber] = useState(
    enquiryDetails.customer.customerPhone
  );
  const [pickupCPNameError, setPickupCPNameError] = useState(" ");
  const [pickupCPNumberError, setPickupCPNumberError] = useState(" ");
  const [dropoffCPNameError, setDropoffCPNameError] = useState(" ");
  const [dropOffCPNumberError, setDropOffCPNumberError] = useState(" ");

  const validateData = () => {
    let isValid = true;

    setPickupCPNameError("");
    setPickupCPNumberError("");
    setDropoffCPNameError("");
    setDropOffCPNumberError("");

    const errorMsg = "Field cannot be empty";

    if (pickupContactPersonName.trim() === "") {
      setPickupCPNameError(errorMsg);
      isValid = false;
    }
    if (!pickupContactPersonNumber || pickupContactPersonNumber.length < 10) {
      setPickupCPNumberError("Invalid contact number");
      isValid = false;
    }
    if (dropOffContactPersonName.trim() === "") {
      setDropoffCPNameError(errorMsg);
      isValid = false;
    }
    if (!dropOffContactPersonNumber || dropOffContactPersonNumber.length < 10) {
      setDropOffCPNumberError("Invalid contact number");
      isValid = false;
    }

    return isValid;
  };

  const handleContactName = (e, setField, setError) => {
    var value = e.target.value;
    setField(value);
    if (value.length > 50) {
      setError("Name should not exceed 50 characters");
    } else {
      setError("");
    }
  };
  const handleContactNumber = (e, setField, setError) => {
    var value = e.target.value;
    value = value.replace(/[^0-9]/g, "");
    if (value.length <= 10) {
      setField(value);
    }
  };

  const handleSubmit = async (e) => {
    console.log("click");
    const isValid = validateData();
    if (!isValid) {
      return;
    }
    setTimeout(() => {
      setDropOffCPNumberError("");
      setPickupCPNumberError("");
      const ContactPersonDetails = {
        pickupContactPerson: {
          name: pickupContactPersonName,
          phoneNumber: pickupContactPersonNumber,
        },
        dropOffContactPerson: {
          name: dropOffContactPersonName,
          phoneNumber: dropOffContactPersonNumber,
        },
      };
      setContactPersonDetails(ContactPersonDetails);
      setStep(CONFIRM_BOOKING_DIALOG_STEPS.CONFIRM_AND_PAY);
    }, 100);
  };
  return (
    <Box id="contact" sx={{ maxWidth: "600px", margin: "auto", mt: 4 }}>
      <Box>
        {/* Contact Details Section */}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                p: 3,
                backgroundColor: "#ffffff",
                borderRadius: 2,
                boxShadow: "0px 2px 10px rgba(0,0,0,0.1)",
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 700,
                  color: "#333333",
                  mb: 3,
                }}
              >
                Pickup Contact Details
              </Typography>
              <CustomTextInput
                label="Contact Person Name"
                handleInputChange={(e) =>
                  handleContactName(
                    e,
                    setPickupContactPersonName,
                    setPickupCPNameError
                  )
                }
                value={pickupContactPersonName}
                error={pickupCPNameError}
                containerStyle={{ marginBottom: 2 }}
              />
              <CustomTextInput
                label="Mobile Number"
                handleInputChange={(e) =>
                  handleContactNumber(
                    e,
                    setPickupContactPersonNumber,
                    setPickupCPNumberError
                  )
                }
                value={pickupContactPersonNumber}
                error={pickupCPNumberError}
                containerStyle={{ marginBottom: 2 }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                p: 3,
                backgroundColor: "#ffffff",
                borderRadius: 2,
                boxShadow: "0px 2px 10px rgba(0,0,0,0.1)",
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 700,
                  color: "#333333",
                  mb: 3,
                }}
              >
                Drop-Off Contact Details
              </Typography>
              <CustomTextInput
                label="Contact Person Name"
                handleInputChange={(e) =>
                  handleContactName(
                    e,
                    setDropOffContactPersonName,
                    setDropoffCPNameError
                  )
                }
                value={dropOffContactPersonName}
                error={dropoffCPNameError}
                containerStyle={{ marginBottom: 2 }}
              />
              <CustomTextInput
                label="Mobile Number"
                handleInputChange={(e) =>
                  handleContactNumber(
                    e,
                    setDropOffContactPersonNumber,
                    setDropOffCPNumberError
                  )
                }
                value={dropOffContactPersonNumber}
                error={dropOffCPNumberError}
                containerStyle={{ marginBottom: 2 }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 4,
        }}
      >
        <Button
          sx={{
            color: "#FFFFFF",
            background: "#0C7F44",
            "&:hover": {
              backgroundColor: "#0A6740",
              opacity: 0.85,
            },
            borderRadius: 1,
            padding: "12px 30px",
            fontSize: "1rem",
            boxShadow: "0px 2px 8px rgba(0,0,0,0.15)",
          }}
          disabled={
            pickupContactPersonName === "" ||
            pickupContactPersonNumber === "" ||
            dropOffContactPersonName === "" ||
            dropOffContactPersonNumber === "" ||
            isContactDetailsUploaded === SAVING_STATUS.SENDING
          }
          onClick={handleSubmit}
        >
          Save & Continue
        </Button>
      </Box>
    </Box>
  );
}

export default WhatsAppPickUpDetails;
