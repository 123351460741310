import { adminApi } from "../config";

export const getEnquiryStats = async () => {
  try {
    const response = await adminApi.get("/admin/dashboard/enquiry-stats");
    return response;
  } catch (error) {
    return error;
  }
};

export const getTripStats = async () => {
  try {
    const response = await adminApi.get("/admin/dashboard/trip-stats");
    return response;
  } catch (error) {
    return error;
  }
};

export const getKycStats = async () => {
  try {
    const response = await adminApi.get("/admin/dashboard/kyc-stats");
    return response;
  } catch (error) {
    return error;
  }
};

export const getSupportTicketStats = async () => {
  try {
    const response = await adminApi.get(
      "/admin/dashboard/support-tickets-stats"
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getCustomerActivityStats = async () => {
  try {
    const response = await adminApi.get(
      "/admin/dashboard/customer-activity-stats"
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getTransporterActivityStats = async () => {
  try {
    const response = await adminApi.get(
      "/admin/dashboard/transporter-activity-stats"
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getDriverAvailabilityStats = async () => {
  try {
    const response = await adminApi.get(
      "/admin/dashboard/driver-availability-stats"
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getTransporterVehicleAvailabilityStats = async () => {
  try {
    const response = await adminApi.get(
      "/admin/dashboard/transporter-vehicle-availability-stats"
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getpaymentStats  = async() => await adminApi.get("/transaction/all-transactions") 
