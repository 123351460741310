// Transporter Layout after user has logged in .
import { Box } from "@mui/system";
import Sidebar from "../component/Sidebar/Sidebar";
import { Outlet } from "react-router";
import {
  AppBar,
  Badge,
  CssBaseline,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { adminRoutes } from "../routes/adminRoutes";
import { useEffect, useState } from "react";
import {
  equalTo,
  onChildAdded,
  onValue,
  orderByChild,
  query,
  ref,
  update,
} from "firebase/database";
import { firebaseRDB } from "../firebase";
import NotificationPopup from "../component/NotificationPopup";
import { updateAdminFCMToken } from "../api/fcmToken";
import Cookies from "js-cookie";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import { toast } from "sonner";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";

const AdminLayout = () => {
  const adminId = Cookies.get("loggedInAdminId");
  const navigate = useNavigate();
  const location = useLocation();
  const [isTokenFound, setTokenFound] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isSnackbarOpen, setisSnackbarOpen] = useState(false);
  const adminFcmToken = sessionStorage.getItem("adminfcmToken");
  const adminToken = Cookies.get("admintoken");
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    const userId = adminId;
    // Replace with actual user ID
    const notificationsRef = ref(firebaseRDB, `history/generic/${userId}`);
    // Query to filter unread messages
    const unreadQuery = query(
      notificationsRef,
      orderByChild("is_read"),
      equalTo(false)
    );

    // Fetch unread messages
    const unsubscribe = onValue(unreadQuery, (snapshot) => {
      setUnreadCount(snapshot.size);
      console.log("Unread messages::", snapshot.size);
    });

    return unsubscribe;
  }, [adminId]);

  useEffect(() => {
    const userId = adminId; // Replace with actual user ID
    const notificationsRef = ref(firebaseRDB, `history/generic/${userId}`);
    const unsubscribe = onChildAdded(notificationsRef, async (snapshot) => {
      const notificationId = snapshot.key;
      const notificationData = snapshot.val();

      if (!notificationData.isProcessed) {
        toast.success(notificationData?.title, {
          description: notificationData?.body,
          icon: <CircleNotificationsIcon />,
          closeButton: true,
          style: {
            maxWidth: 400,
            color: "green",
          },
        });

        try {
          await update(
            ref(firebaseRDB, `history/generic/${userId}/${notificationId}`),
            {
              isProcessed: true,
            }
          );
        } catch (error) {
          console.error("Error updating notification status:", error);
        }
      }
    });
    return unsubscribe;
  }, [adminId]);

  if (!adminToken || adminToken === undefined)
    return <Navigate to="/" replace />;
  return (
    <Box sx={{ height: "100vh" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          boxShadow: "none",
          background: "#fff",
          borderBottom: "1px solid #adadad",
        }}
      >
        <Toolbar sx={{ height: "6rem" }}>
          <Stack direction="row" alignItems="center" spacing="1.25rem">
            <img
              src="/assets/icons/LZ-logo-face.svg"
              style={{ marginBottom: ".35rem" }}
              alt="logoface"
            />
            <img src="/assets/icons/LZ-logo.svg" alt="logo" />
          </Stack>

          <Stack direction="row" alignItems="center" ml="auto" gap={4}>
            <Badge
              sx={{
                cursor: "pointer",
                background: location.pathname.includes("admin/app/notification")
                  ? "#DDE7EF"
                  : "#fff",
                padding: "2px",
                borderRadius: "4px",
              }}
              onClick={() => {
                navigate("notifications");
              }}
              badgeContent={unreadCount}
              color="success"
            >
              {/* <MailIcon color="action" /> */}
              <NotificationsActiveOutlinedIcon color="action" />
            </Badge>
            {/* <NotificationsActiveOutlinedIcon
              sx={{
                color: "#333",
                background: location.pathname.includes("admin/app/notification")
                  ? "#DDE7EF"
                  : "#fff",
                cursor: "pointer",
                borderRadius: 1,
                fontSize: 26,
              }}
              onClick={() => {
                navigate("notifications");
              }}
            /> */}
            <Box>
              <Typography color="#0C7F44" fontWeight={700}>
                Admin
              </Typography>
            </Box>
          </Stack>
        </Toolbar>
      </AppBar>

      <Box sx={{ display: "flex", height: "100vh" }}>
        {!location.pathname.endsWith("admin/app/notifications") && (
          <Sidebar routes={adminRoutes} />
        )}
        <Box mt="6rem" sx={{ flexGrow: 1 }}>
          <Outlet />
        </Box>
      </Box>
      <NotificationPopup
        isOpen={isSnackbarOpen}
        header={notification.title}
        message={notification.body}
        setisSnackbarOpen={setisSnackbarOpen}
      />
    </Box>
  );
};
export default AdminLayout;
