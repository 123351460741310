// Transporter Layout after user has logged in .
import { Box } from "@mui/system";
import Sidebar from "../component/Sidebar/Sidebar";
import { Outlet } from "react-router";
import {
  AppBar,
  Badge,
  CssBaseline,
  Grid,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import Cookies from "js-cookie";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { transporterRoutes } from "../routes/transporterRoutes";
import { useDispatch, useSelector } from "react-redux";
import { setTransporterName } from "../redux/slices/transporterNameSlice";
import { setTransporterId } from "../redux/slices/transporterIdSlice";
import { getTransporterProfile } from "../api/Transporter/profile";
import { setTransporterProfile } from "../redux/slices/transporterProfileSlice";
import GetInTouch from "../component/Common/GetInTouch";
import { getToken } from "@firebase/messaging";
import NotificationPopup from "../component/NotificationPopup";
import { firebaseRDB, messaging, onMessageListener } from "../firebase";
import { updateTransporterFCMToken } from "../api/fcmToken";
import useToast from "../hooks/useToast";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import { setTransporterNotificationPreference } from "../redux/slices/transporterSlice";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  equalTo,
  onChildAdded,
  onValue,
  orderByChild,
  query,
  ref,
  update,
} from "firebase/database";
import { toast } from "sonner";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import { NotificationContext } from "../context/notificationContext";

const TransporterLayout = () => {
  const [isNotificationPanel, setIsNotificationPanel] = useState(false);
  const [ftueStatus, setftueStatus] = useState("");
  const dispatch = useDispatch();
  const { handleOpenToast, ToastMessage } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const profileName = useSelector((state) => state.transporterName?.name);
  const number = useSelector(
    (state) => state.supportNumbers?.transporterPortal
  );
  const transporterfcmToken = sessionStorage.getItem("transporterfcmToken");

  const [isTokenFound, setTokenFound] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isSnackbarOpen, setisSnackbarOpen] = useState(false);
  const transporterId = useSelector((state) => state.transporterId.id);
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    if (localStorage.getItem("ftueStatus") !== "PENDING") {
      getTransporterProfile()
        .then((response) => {
          const currentftueStatus = response.data.transporter?.ftueStatus;
          setftueStatus(currentftueStatus);
          dispatch(setTransporterId(response.data.transporter._id));
          if (currentftueStatus === "DONE") {
            // requestUserPermission()
          }
          dispatch(setTransporterName(response.data.transporter.fullName));
          dispatch(setTransporterProfile(response.data?.transporter));
          dispatch(
            setTransporterNotificationPreference(
              response.data?.transporter?.notificationPreferences
            )
          );
        })
        .catch((err) => {
          handleOpenToast("Something went wrong. Please refresh page");
        });
    }
  }, []);

  useEffect(() => {
    const userId = transporterId;
    // Replace with actual user ID
    const notificationsRef = ref(firebaseRDB, `history/generic/${userId}`);
    // Query to filter unread messages
    const unreadQuery = query(
      notificationsRef,
      orderByChild("is_read"),
      equalTo(false)
    );

    // Fetch unread messages
    const unsubscribe = onValue(unreadQuery, (snapshot) => {
      setUnreadCount(snapshot.size);
      console.log("Unread messages::", snapshot.size);
    });

    return unsubscribe;
  }, [transporterId]);

  useEffect(() => {
    const userId = transporterId; // Replace with actual user ID
    const notificationsRef = ref(firebaseRDB, `history/generic/${userId}`);
    const unsubscribe = onChildAdded(notificationsRef, async (snapshot) => {
      const notificationId = snapshot.key;
      const notificationData = snapshot.val();

      if (!notificationData.isProcessed) {
        toast.success(notificationData?.title, {
          description: notificationData?.body,
          icon: <CircleNotificationsIcon />,
          closeButton: true,
          style: {
            maxWidth: 400,
            color: "green",
          },
        });

        try {
          await update(
            ref(firebaseRDB, `history/generic/${userId}/${notificationId}`),
            {
              isProcessed: true,
            }
          );
        } catch (error) {
          console.error("Error updating notification status:", error);
        }
      }
    });
    return unsubscribe;
  }, [transporterId]);

  // onMessageListener()
  //   .then((payload) => {
  //     setNotification({
  //       title: payload.notification.title,
  //       body: payload.notification.body,
  //     });
  //     setisSnackbarOpen(true);
  //   })
  //   .catch((err) => {
  //     console.log("failed: ", err);
  //   });

  const token = Cookies.get("transportertoken");
  // if token is present means user is authorized and can access app
  if (!token || token === undefined) {
    return <Navigate to="/" replace />;
  }
  return (
    <Box sx={{ height: "100vh" }}>
      <ToastMessage />
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          boxShadow: "none",
          background: "#fff",
          borderBottom: "1px solid #adadad",
        }}
      >
        <Toolbar sx={{ height: "6rem" }}>
          <Stack direction="row" alignItems="center" spacing="1.25rem">
            <img
              src="/assets/icons/LZ-logo-face.svg"
              style={{ marginBottom: ".35rem" }}
              alt="logoface"
            />
            <img src="/assets/icons/LZ-logo.svg" alt="logo" />
          </Stack>

          <Box
            sx={{
              marginLeft: "auto",
              display: "flex",
              gap: "2.5rem",
              alignItems: "center",
            }}
          >
            {ftueStatus === "DONE" && (
              // <NotificationsActiveOutlinedIcon
              //   sx={{
              //     color: "#333",
              //     background: location.pathname.includes(
              //       "transporter/notification"
              //     )
              //       ? "#DDE7EF"
              //       : "#fff",
              //     cursor: "pointer",
              //     borderRadius: 1,
              //     fontSize: 26,
              //   }}
              //   onClick={() => {
              //     navigate("notifications");
              //   }}
              // />
              <Badge
                sx={{
                  cursor: "pointer",
                  background: location.pathname.includes(
                    "transporter/notification"
                  )
                    ? "#DDE7EF"
                    : "#fff",
                  padding: "2px",
                  borderRadius: "4px",
                }}
                onClick={() => {
                  navigate("notifications");
                }}
                badgeContent={unreadCount}
                color="success"
              >
                {/* <MailIcon color="action" /> */}
                <NotificationsActiveOutlinedIcon color="action" />
              </Badge>
            )}
            {/* <Grid
              container
              spacing={2}
              
              mx="auto"
              my="auto"
              border="1px solid #ececec"
              px="12px"
              py="6px"
              borderRadius="6px"
              backgroundColor="#eefaed"
            > */}
            <Box
              color="black"
              display="flex"
              alignItems="center"
              gap={1}
              border="1px solid #ececec"
              px="12px"
              py="6px"
              borderRadius="6px"
              backgroundColor="#eefaed"
              sx={{
                paddingRight: "8px",
              }}
            >
              <AccountCircleIcon />
              <Stack>
                <Typography color="#333" fontWeight={700} fontSize="0.75rem">
                  {profileName ? profileName : "Guest"}
                </Typography>
                <Typography color="#0C7F44" fontSize="0.6rem">
                  LoadEazy Partner
                </Typography>
              </Stack>
            </Box>
            {/* </Grid> */}
            <Box>
              <GetInTouch />
              <Typography color="#0C7F44" fontWeight={700} align="right">
                {number}
              </Typography>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: "100vw",
        }}
      >
        {!location.pathname.endsWith("/transporter/notifications") && (
          <Sidebar routes={transporterRoutes} />
        )}
        <Box mt="6rem" sx={{ flexGrow: 1 }}>
          {/* <Toolbar sx={{ height: "6rem" }} /> */}
          <NotificationContext.Provider value={{ unreadCount }}>
            <Outlet />
          </NotificationContext.Provider>
        </Box>
      </Box>
      <NotificationPopup
        isOpen={isSnackbarOpen}
        header={notification.title}
        message={notification.body}
        setisSnackbarOpen={setisSnackbarOpen}
      />
    </Box>
  );
};
export default TransporterLayout;
