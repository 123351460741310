import { Stack, Typography } from "@mui/material";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";

export default function GetInTouch() {
  return (
    <Stack direction="row" gap={0.25}>
      <PhoneRoundedIcon sx={{ color: "#333", fontSize: "17px" }} />
      <Typography
        variant="p"
        sx={{ color: "#333333", fontSize: ".875em", lineHeight: "1rem" }}
        gutterBottom={false}
        noWrap
      >
        Get in touch
      </Typography>
    </Stack>
  );
}
