import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  CircularProgress,
  Box,
  Autocomplete,
} from "@mui/material";
import {
  MapContainer,
  TileLayer,
  Marker,
  Tooltip,
  useMap,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import {
  fetchAllDrivers,
  fetchAllTransporters,
  getAllDriverLocation,
  getDriverLocation,
} from "../../../api/Admin/vehicleTracking";
import useToast from "../../../hooks/useToast";

const truckIcon = new L.Icon({
  iconUrl: "/assets/icons/truck.svg",
  iconSize: [15, 15],
  iconAnchor: [12, 25],
  popupAnchor: [0, -25],
});

const FitToBounds = () => {
  const map = useMap();

  useEffect(() => {
    map.fitBounds([
      [20.0, 60.0],
      [8.0, 98.0],
    ]);
  }, [map]);

  return null;
};

const VehicleTracker = () => {
  const { ToastMessage, handleOpenToast } = useToast();
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isListFetched, setIsListFetched] = useState(false);
  const [transporter, setTransporter] = useState("");
  const [matchedTransporters, setMatchedTransporters] = useState([]);
  const [driver, setDriver] = useState("");
  const [matchedDrivers, setMatchedDrivers] = useState([]);
  const [transporteOptions, setTransporterOptions] = useState([]);
  const [driverOptions, setDriverOptions] = useState([]);

  useEffect(() => {
    if (transporter.trim() === "") {
      setTransporterOptions([]);
      return;
    }

    const fetchOptions = async () => {
      setIsListFetched(true);
      try {
        const response = await fetchAllTransporters(transporter);
        setTransporterOptions(
          response.data.data.transporters.map((e) => e.transporterName) || []
        );
        setMatchedTransporters(
          response.data.data.transporters.map((e) => ({
            trName: e.transporterName,
            tId: e.transporterId,
          })) || []
        );
      } catch (error) {
        console.error("Error fetching data:", error);
        setTransporterOptions([]);
      } finally {
        setIsListFetched(false);
      }
    };

    const debounceFetch = setTimeout(fetchOptions, 300); // Debounce API calls

    return () => clearTimeout(debounceFetch); // Cleanup timeout
  }, [transporter]);

  useEffect(() => {
    if (driver.trim() === "") {
      setDriverOptions([]);
      return;
    }

    const fetchOptions = async () => {
      setIsListFetched(true);
      try {
        const response = await fetchAllDrivers(driver);
        setDriverOptions(
          response.data.data.drivers.map((e) => e.driverName) || []
        );
        setMatchedDrivers(
          response.data.data.drivers.map((e) => ({
            drName: e.driverName,
            dId: e.driverId,
          })) || []
        );
      } catch (error) {
        console.error("Error fetching data:", error);
        setDriverOptions([]);
      } finally {
        setIsListFetched(false);
      }
    };

    const debounceFetch = setTimeout(fetchOptions, 300); // Debounce API calls

    return () => clearTimeout(debounceFetch); // Cleanup timeout
  }, [driver]);

  const handleSearch = async (e, selectedOption) => {
    if (selectedOption) {
      setLoading(true);
      let newVehicles = [];
      if (transporter) {
        const transporter = matchedTransporters.find(
          (e) => e.trName === selectedOption
        );
        let response = null;
        try {
          // alert(transporter.tId);
          response = await getAllDriverLocation(transporter.tId);
        } catch (err) {
          if (err.response) {
            setLoading(false);
            handleOpenToast(err.response.data.message, "error");
          } else if (
            err.message == "No drivers associated with this transporter found"
          ) {
            setLoading(false);
            handleOpenToast(
              "No drivers associated with this transporter found",
              "error"
            );
          } else {
            handleOpenToast("Something went wrong", "error");
          }
          return;
        }

        const validPositions = response.data.locations.filter(
          (vehicle) =>
            vehicle &&
            typeof vehicle.latitude === "number" &&
            typeof vehicle.longitude === "number" &&
            !isNaN(vehicle.latitude) &&
            !isNaN(vehicle.longitude)
        );

        const promises = validPositions.map(async (vehicle) => {
          const { latitude, longitude } = vehicle;
          const response = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
          );
          const address =
            response.data.results[0]?.formatted_address || "Unknown address";
          return { ...vehicle, address };
        });

        newVehicles = await Promise.all(promises);
      } else if (driver) {
        let response;
        const driver = matchedDrivers.find((e) => e.drName === selectedOption);
        try {
          response = await getDriverLocation(driver.dId);
        } catch (err) {
          if (err.response) {
            handleOpenToast(
              err.response.data.message ?? "Something went wrong",
              "error"
            );
          } else {
            handleOpenToast("Something went wrong", "error");
          }
          setLoading(false);
          return;
        }

        const vehicle = response.data.locations;
        if (vehicle) {
          const { latitude, longitude } = vehicle;
          const response = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
          );
          const address =
            response.data.results[0]?.formatted_address || "Unknown address";
          newVehicles = [{ ...vehicle, address }];
        }
      }

      setVehicles(newVehicles);
      setLoading(false);
    }
  };

  return (
    <Box p={2}>
      <ToastMessage />
      <Grid container spacing={1} alignItems="center" mb={2}>
        <Grid item xs={12} sm={5}>
          <Autocomplete
            sx={{
              backgroundColor: "#f0f0f0",
              borderRadius: 2,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  height: "3.5rem",
                },
                "&:hover fieldset": {
                  borderColor: "#2e7d32", // Hover effect
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#1b5e20", // Focus effect
                },
              },
            }}
            fullWidth
            options={transporteOptions}
            freeSolo
            value={transporter}
            onInputChange={(event, newInputValue) => {
              setDriver("");
              setMatchedDrivers([]);
              setDriverOptions([]);
              setTransporter(newInputValue);
            }}
            onChange={handleSearch}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Transporter"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isListFetched ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
          {/* <TextField
            fullWidth
            variant="outlined"
            placeholder="Transporter name"
            value={transporterId}
            onChange={(e) => setTransporterId(e.target.value)}
            InputProps={{
              endAdornment: (
                <img src="/assets/icons/search-icon.svg" alt="search" />
              ),
            }}
            sx={{ marginBottom: { xs: 1, sm: 0 } }}
          /> */}
        </Grid>
        <Grid item xs={12} sm={5}>
          <Autocomplete
            sx={{
              backgroundColor: "#f0f0f0",
              borderRadius: 2,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  height: "3.5rem",
                },
                "&:hover fieldset": {
                  borderColor: "#2e7d32", // Hover effect
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#1b5e20", // Focus effect
                },
              },
            }}
            options={driverOptions}
            freeSolo
            value={driver}
            onInputChange={(event, newInputValue) => {
              setTransporter("");
              setMatchedTransporters([]);
              setTransporterOptions([]);
              setDriver(newInputValue);
            }}
            onChange={handleSearch}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Driver"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isListFetched ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
          {/* <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by driver id"
            value={driverId}
            onChange={(e) => setDriverId(e.target.value)}
            InputProps={{
              endAdornment: (
                <img src="/assets/icons/search-icon.svg" alt="search" />
              ),
            }}
            sx={{ marginBottom: { xs: 1, sm: 0 } }}
          /> */}
        </Grid>
        <Grid item xs={12} sm={2}>
          {/* <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSearch}
            sx={{ height: "3rem", fontSize: "16px" }}
          >
            Search
          </Button> */}
        </Grid>
      </Grid>

      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="600px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box mt={2} height="600px" width="100%" overflow="auto">
          <Box height="1200px" width="100%">
            <MapContainer
              center={[20.5937, 78.9629]}
              zoom={5}
              style={{ height: "100%", width: "100%" }}
              scrollWheelZoom={true}
            >
              <FitToBounds />
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              {vehicles.map((vehicle) => (
                <Marker
                  key={vehicle.id}
                  position={[vehicle.latitude, vehicle.longitude]}
                  icon={truckIcon}
                >
                  <Tooltip
                    direction="top"
                    offset={[0, -25]}
                    opacity={1}
                    interactive
                  >
                    <div>
                      <strong>Driver Name: {vehicle.driverName}</strong>
                      <br />
                      Mobile Number: {vehicle.phone}
                      <br />
                      Address: {vehicle.address}
                    </div>
                  </Tooltip>
                </Marker>
              ))}
            </MapContainer>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default VehicleTracker;
