import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import {
  Box,
  Button,
  Divider,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { guestApi } from "../../api/config";
import {
  ref,
  onValue,
  off,
  limitToFirst,
  startAt,
  query,
  orderByKey,
  limitToLast,
  orderByChild,
  endAt,
  get,
  endBefore,
  update,
  equalTo,
} from "firebase/database";
import { getDatabase } from "firebase/database";
import { firebaseApp, firebaseRDB } from "../../firebase";
import { useSelector } from "react-redux";
import moment from "moment";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import GradientCirlularProgress from "../../component/Common/GradientCirlularProgress";
import { useNotificationContext } from "../../context/notificationContext";

const CustomerNotificationPage = (props) => {
  const pageSize = 8;
  const navigate = useNavigate();
  const customerId = useSelector((state) => state.customer.id);

  const [notificationList, setNotificationsList] = useState([]);
  const [lastKey, setLastKey] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const [isLoading, setisLoading] = useState(false);
  const [error, seterror] = useState(false);
  const { unreadCount } = useNotificationContext();

  const notificationRef = ref(firebaseRDB, `history/generic/${customerId}`);
  console.log(props.unreadCount);

  useEffect(() => {
    const getNotifications = async () => {
      setisLoading(true);
      const notificationQuery = query(
        notificationRef,
        orderByKey(),
        limitToLast(pageSize)
      );

      const snapshot = await get(notificationQuery);
      if (snapshot.exists()) {
        const items = [];
        snapshot.forEach((childSnapshot) => {
          items.push({
            ...childSnapshot.val(),
            key: childSnapshot.key,
          });
        });
        setNotificationsList(items.reverse());

        if (items.length > 0) {
          setLastKey(items[items.length - 1].key);
        }

        if (items.length < pageSize) {
          setHasMore(false);
        }

        setisLoading(false);

        console.log("NOTIFICATIONS:::", items);
      } else {
        setHasMore(false);
        setisLoading(false);
      }
    };

    getNotifications();

    return () => {
      setHasMore(true);
      setLastKey(null);
    };
  }, []);

  const fetchNextPage = async () => {
    if (!lastKey) return;

    try {
      const snapshot = await get(
        query(
          notificationRef,
          orderByKey(),
          endBefore(lastKey),
          limitToLast(pageSize)
        )
      );

      if (snapshot.exists()) {
        const items = [];
        snapshot.forEach((childSnapshot) => {
          items.push({
            key: childSnapshot.key,
            ...childSnapshot.val(),
          });
        });
        setNotificationsList((prev) => [...prev, ...items.reverse()]);

        if (items.length > 0) {
          setLastKey(items[items.length - 1].key);
        }

        if (items.length < pageSize) {
          setHasMore(false);
        }
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching next page", error);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      // fetch all unread notifications
      const unreadNotifications = notificationList.filter(
        (notification) => !notification.is_read
      );
      console.log("unreadNotifications", unreadNotifications);

      if (unreadNotifications.length > 0) {
        unreadNotifications.forEach(async (notification) => {
          const notificationRef = ref(
            firebaseRDB,
            `history/generic/${customerId}/${notification.key}`
          );
          await update(notificationRef, { is_read: true });
        });

        //  mark all notifications as read
        setNotificationsList((previous) =>
          previous.map((notification) => ({ ...notification, is_read: true }))
        );
      }
    } catch (error) {
      console.error("Error marking all notifications as read", error);
    }
  };

  if (error) {
    return (
      <Box>
        <Typography variant="h4" color="error">
          Something went wrong. Please try again later.
        </Typography>
      </Box>
    );
  }

  // const fetchNotifications = (lastKey = "") => {
  //   const db = getDatabase(firebaseApp);
  //   let itemsRef = ref(
  //     db,
  //     `history/generic/${customerId}` // Ensure 'filter' is correctly defined
  //   );

  //   // Initial query constraints
  //   let queryConstraints = [
  //     orderByChild("timestamp"),
  //     limitToLast(pageSize + 1),
  //   ]; // +1 to check for more items

  //   if (lastKey) {
  //     // If we have a lastKey, fetch the previous set of items, using endAt to specify the starting point
  //     queryConstraints.push(endAt(lastKey));
  //   }

  //   itemsRef = query(itemsRef, ...queryConstraints);

  //   // Listener to fetch data
  //   const unsubscribe = onValue(itemsRef, (snapshot) => {
  //     const data = snapshot.val();
  //     let fetchedItems = data ? Object.values(data) : [];

  //     // Since we are using limitToLast, we need to reverse the fetched items to display them correctly
  //     fetchedItems.reverse();

  //     if (lastKey) {
  //       // Remove the first item which is a duplicate of the last item from the previous fetch
  //       fetchedItems.shift();
  //     }

  //     const newItems = [...notificationList, ...fetchedItems];
  //     setNotificationsList(newItems);

  //     if (fetchedItems.length > 0) {
  //       // Save the timestamp of the last item for pagination
  //       // Note: Ensure that the fetchedItems are not empty before accessing its properties
  //       setLastKey(fetchedItems[fetchedItems.length - 1].timestamp);
  //       setHasMore(true);
  //     } else {
  //       setHasMore(false);
  //     }
  //   });

  //   // Return the unsubscribe function to clean up the listener
  //   return () => unsubscribe();
  // };

  // useEffect(() => {
  //   const unsubscribe = fetchNotifications();

  //   // Clean up the listener when the component unmounts
  //   return () => unsubscribe();
  // }, []);
  return (
    <Box pb={3}>
      <Stack
        position="fixed"
        width="100%"
        sx={{ background: "#EAEAEA", zIndex: 1000 }}
        py={2}
      >
        <Stack
          width="100%"
          m="auto"
          direction="row"
          maxWidth="lg"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            sx={{
              color: "#333",
              fontWeight: 700,
              position: "absolute",
              left: "11rem",
            }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosRoundedIcon />
          </Button>

          <Typography
            fontWeight={700}
            color="#333"
            textAlign="center"
            flexGrow={1}
            variant="h5"
          >
            Notification
          </Typography>

          <Box
            sx={{
              position: "absolute",
              right: "11rem",
            }}
          >
            <Button
              sx={{ textTransform: "none" }}
              onClick={handleMarkAllAsRead}
              disabled={unreadCount === 0}
            >
              <MarkEmailReadOutlinedIcon
                sx={{
                  mx: "5px",
                }}
              />{" "}
              <span> Mark all as read</span>
            </Button>
          </Box>
        </Stack>
      </Stack>
      <Toolbar />
      {isLoading ? (
        <Stack
          m="auto"
          width="100%"
          maxWidth="lg"
          mt={5}
          mb={5}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <GradientCirlularProgress />
        </Stack>
      ) : (
        <>
          <Stack m="auto" maxWidth="lg">
            {notificationList.length > 0 &&
              notificationList.map((each, idx) => {
                if (idx !== 0)
                  return (
                    <Notification
                      data={each}
                      key={idx}
                      setNotificationsList={setNotificationsList}
                    />
                  );
              })}
          </Stack>
          {hasMore && !isLoading && notificationList.length > 0 && (
            <Stack direction="row" justifyContent="center" mt={5}>
              <Button
                variant="containedPrimary"
                sx={{ textTransform: "none" }}
                onClick={() => fetchNextPage()}
              >
                Load More...
              </Button>
            </Stack>
          )}
          {!hasMore && (
            <Typography textAlign="center" fontSize={16} color="#ADADAD" my={5}>
              You're all caught up !
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};
export default CustomerNotificationPage;

const Notification = ({ data, setNotificationsList }) => {
  const customerId = useSelector((state) => state.customer.id);

  const markNotificationAsRead = async (key) => {
    try {
      const notificationRef = ref(
        firebaseRDB,
        `history/generic/${customerId}/${key}`
      );
      await update(notificationRef, { is_read: true });

      setNotificationsList((prev) =>
        prev.map((notification) =>
          notification.key === key
            ? { ...notification, is_read: true }
            : notification
        )
      );
    } catch (error) {
      console.error("Error marking notification as read", error);
    }
  };
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        gap={5}
        maxWidth="lg"
        px={5}
        py={2}
        sx={{
          // background: !data.is_read ? "#F5EFFF" : "",
          cursor: "pointer",
          "&:hover": {
            background: "#f7f5f5",
            cursor: "pointer",
            transition: "background 0.3s ease",
          },
        }}
        onClick={() => markNotificationAsRead(data.key)}
      >
        <Box
          sx={{
            width: "60%",
            display: "flex",
            alignItems: "flex-start",
            gap: 2,
          }}
        >
          <CircleNotificationsIcon
            sx={{
              color: !data.is_read ? "#574964" : "#b7afbd",
              fontSize: 30,
            }}
          />
          <Box>
            <Typography
              color={data.is_read ? "#918899" : "#574964"}
              fontWeight={700}
            >
              {data.title}
            </Typography>
            <Typography color="#ADADAD">{data.body}</Typography>
          </Box>
        </Box>
        <Typography
          color={data.is_read ? "#918899" : "#574964"}
          fontSize={12}
          fontWeight={700}
        >
          {moment(data.timestamp).format("Do MMMM YYYY hh:mm A")}
        </Typography>
      </Stack>
      <Divider />
    </>
  );
};
