import React, { useEffect, useState } from "react";
import { Box, padding } from "@mui/system";
import Sidebar from "../component/Sidebar/Sidebar";
import { Outlet, useNavigate } from "react-router";
import { Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Cookies from "js-cookie";
import {
  AppBar,
  Badge,
  CircularProgress,
  CssBaseline,
  Grid,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import MailIcon from "@mui/icons-material/Mail";

import { customerRoutes } from "../routes/customerRoutes";
import useToast from "../hooks/useToast";
import { setCustomerProfile } from "../redux/slices/customerSlice";
import { getCustomerProfile } from "../api/Customer/enquiry";
import { LOADING_STATUS } from "../constants/constant";
import GetInTouch from "../component/Common/GetInTouch";
import ErrorScreen from "../component/Common/ErrorScreen";
import { firebaseRDB, messaging, onMessageListener } from "../firebase";
import NotificationPopup from "../component/NotificationPopup";
import { updateCustomerFCMToken } from "../api/fcmToken";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import {
  equalTo,
  onChildAdded,
  onValue,
  orderByChild,
  query,
  ref,
  update,
} from "firebase/database";
import { toast } from "sonner";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { NotificationContext } from "../context/notificationContext";

const CustomerLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isFavoritesPage = location.pathname.includes("favorites");
  const { handleOpenToast, ToastMessage } = useToast();

  const [isProfileFetched, setIsProfileFetched] = useState("");
  const [isTokenFound, setTokenFound] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isSnackbarOpen, setisSnackbarOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  const number = useSelector((state) => state.supportNumbers?.customerPortal);
  const name = useSelector((state) => state.customer.name);
  const token = Cookies.get("customertoken");
  const fcmToken = sessionStorage.getItem("customerfcmToken");

  const customerId = useSelector((state) => state.customer.id);

  useEffect(() => {
    const userId = customerId;
    // Replace with actual user ID
    const notificationsRef = ref(firebaseRDB, `history/generic/${userId}`);
    // Query to filter unread messages
    const unreadQuery = query(
      notificationsRef,
      orderByChild("is_read"),
      equalTo(false)
    );

    // Fetch unread messages
    const unsubscribe = onValue(unreadQuery, (snapshot) => {
      setUnreadCount(snapshot.size);
      console.log("Unread messages::", snapshot.size);
    });

    return unsubscribe;
  }, [customerId]);

  useEffect(() => {
    const userId = customerId; // Replace with actual user ID
    const notificationsRef = ref(firebaseRDB, `history/generic/${userId}`);
    const unsubscribe = onChildAdded(notificationsRef, async (snapshot) => {
      const notificationId = snapshot.key;
      const notificationData = snapshot.val();

      if (!notificationData.isProcessed) {
        toast.success(notificationData?.title, {
          description: notificationData?.body,
          icon: <CircleNotificationsIcon />,
          closeButton: true,
          style: {
            maxWidth: 400,
            color: "green",
          },
        });

        try {
          await update(
            ref(firebaseRDB, `history/generic/${userId}/${notificationId}`),
            {
              isProcessed: true,
            }
          );
        } catch (error) {
          console.error("Error updating notification status:", error);
        }
      }
    });
    return unsubscribe;
  }, [customerId]);

  // background notification listener
  // onMessageListener()
  //   .then((payload) => {
  //     // whenever new notification arrives ,store it in state.
  //     setNotification({
  //       title: payload.notification.title,
  //       body: payload.notification.body,
  //     });
  //     // turn on Notification Popup
  //     setisSnackbarOpen(true);
  //   })
  //   .catch((err) => {
  //     handleOpenToast("Something went wrong. Please refresh page !", "error");
  //     console.log("failed: ", err);
  //   });

  // const generateFCMToken = async () => {
  //   try {
  //     const token = await getToken(messaging, {
  //       vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  //     });
  //     return token;
  //   } catch (err) {
  //     console.log(err);
  //     return false;
  //   }
  // };

  // const updateFCMToken = async () => {
  //   // const fcmtoken = await generateFCMToken();
  //   if (fcmtoken) {
  //     // add fcm token to user document
  //     try {
  //       await updateCustomerFCMToken(fcmtoken);
  //       sessionStorage.setItem("customerfcmToken", JSON.stringify(fcmtoken));
  //       setTokenFound(true);
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   }
  // };

  // async function requestUserPermission() {
  //   if (fcmToken) {
  //     return;
  //   }
  //   if (Notification.permission === "denied") {
  //     alert(
  //       "Notifications are blocked. Please enable them in your browser settings."
  //     );
  //   } else if (Notification.permission === "default") {
  //     const permission = await Notification.requestPermission();
  //     if (permission === "granted") {
  //       console.log("Notification permission granted.");
  //       // You can now send notifications
  //       // updateFCMToken();
  //     }
  //   } else if (Notification.permission === "granted") {
  //     // updateFCMToken();
  //   }
  // }

  const fetchCustomerProfile = async () => {
    setIsProfileFetched(LOADING_STATUS.LOADING);
    try {
      const response = await getCustomerProfile();
      if (response.status === 200) {
        dispatch(setCustomerProfile(response.data.customer));
        setIsProfileFetched(LOADING_STATUS.LOADED);
        return true;
      } else {
        setIsProfileFetched(LOADING_STATUS.FAILED);
        handleOpenToast("Something went wrong. Please refresh page !", "error");
        return false;
      }
    } catch (err) {
      setIsProfileFetched(LOADING_STATUS.FAILED);
      handleOpenToast("Something went wrong. Please refresh page !", "error");
      return false;
    }
  };

  const renderOutlet = () => {
    if (isProfileFetched === LOADING_STATUS.LOADING) {
      <Box display="flex" justifyContent="center" alignItems="center" p={5}>
        <CircularProgress />
      </Box>;
    } else if (isProfileFetched === LOADING_STATUS.LOADED) {
      return (
        <NotificationContext.Provider value={{ unreadCount }}>
          <Outlet />
        </NotificationContext.Provider>
      );
    } else if (isProfileFetched === LOADING_STATUS.FAILED) {
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          <ErrorScreen onRetry={fetchCustomerProfile} />;
        </Box>
      );
    }
  };

  useEffect(() => {
    fetchCustomerProfile();
    // requestUserPermission();
  }, []);

  // if token is present means user is authorized and can access app
  if (!token || token === undefined) {
    return <Navigate to="/" replace />;
  }

  // ... (Firebase initialization)

  return (
    <Box>
      <NotificationPopup
        isOpen={isSnackbarOpen}
        header={notification.title}
        message={notification.body}
        setisSnackbarOpen={setisSnackbarOpen}
      />
      <ToastMessage />
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          boxShadow: "none",
          background: "#fff",
          borderBottom: "1px solid #adadad",
        }}
      >
        <Toolbar sx={{ height: "6rem" }}>
          <Stack direction="row" alignItems="center" spacing="1.25rem">
            <img
              src="/assets/icons/LZ-logo-face.svg"
              style={{ marginBottom: ".35rem" }}
              alt="logoface"
            />
            <img src="/assets/icons/LZ-logo.svg" alt="logo" />
          </Stack>

          <Box
            sx={{
              marginLeft: "auto",
              display: "flex",
              gap: "1.5rem",
              alignItems: "center",
            }}
          >
            <Box
              bgcolor={isFavoritesPage && "#DDE7EF"}
              display="flex"
              alignItems="center"
              borderRadius="5px"
              p="2px"
              sx={{ cursor: "pointer" }}
            >
              <StarOutlineIcon
                onClick={() => {
                  navigate("favorites");
                }}
                sx={{
                  fill: isFavoritesPage ? "#333" : "#6D6D6D",
                  width: "26px",
                  height: "26px",
                }}
              />
            </Box>
            {/* TODO: notification */}
            <Badge
              sx={{
                cursor: "pointer",
                background: location.pathname.includes("customer/notification")
                  ? "#DDE7EF"
                  : "#fff",
                padding: "2px",
                borderRadius: "4px",
              }}
              onClick={() => {
                navigate("notifications");
              }}
              badgeContent={unreadCount}
              color="success"
            >
              {/* <MailIcon color="action" /> */}
              <NotificationsActiveOutlinedIcon color="action" sx={{}} />
            </Badge>
            {/* <NotificationsActiveOutlinedIcon
              sx={{
                color: "#333",
                background: location.pathname.includes("customer/notification")
                  ? "#DDE7EF"
                  : "#fff",
                cursor: "pointer",
                borderRadius: 1,
                fontSize: 26,
              }}
              onClick={() => {
                navigate("notifications");
              }}
            /> */}
            <Grid
              container
              spacing={2}
              alignItems="center"
              mx="auto"
              my="auto"
              border="1px solid #ececec"
              px="12px"
              py="6px"
              borderRadius="6px"
              backgroundColor="#eefaed"
            >
              <Box
                color="black"
                display="flex"
                sx={{
                  paddingRight: "8px",
                }}
              >
                <AccountCircleIcon
                  sx={{
                    width: "100%",
                  }}
                />
              </Box>
              <Stack>
                <Typography color="#333" fontSize="0.75rem" fontWeight={700}>
                  {name ? name : "Guest"}
                </Typography>
                <Typography color="#0C7F44" fontSize="0.6rem">
                  Customer
                </Typography>
              </Stack>
            </Grid>
            <Box>
              <GetInTouch />
              <Typography
                color="#0C7F44"
                variant="body2"
                fontWeight={700}
                align="right"
              >
                {number}
              </Typography>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Box sx={{ display: "flex" }}>
        {!location.pathname.endsWith("customer/notifications") && (
          <Sidebar routes={customerRoutes} />
        )}
        <Box mt="6rem" sx={{ flexGrow: 1 }}>
          {/* <Toolbar sx={{ height: "6rem" }} /> */}
          {renderOutlet()}
        </Box>
      </Box>
    </Box>
  );
};
export default CustomerLayout;
